import React from 'react'
import styles from '../assets/styles/components/NavCompressed.module.css';
import { Link, useNavigate,useLocation} from 'react-router-dom';
import iconMenu from '../assets/img/menu-2.png';

const NavCompressed = ({viewMenu,setViewMenu}) => {

	const { pathname } = useLocation();

    const handleView = (event) => {
		event.preventDefault();
		setViewMenu(!viewMenu);
	};

    const navigate = useNavigate();

	const handleItemView = (event) => {
		event.preventDefault();
		const page = event.target.name;
		console.log(page)
		setViewMenu(!viewMenu);
		navigate(page);
	};



  return (
    <div className={styles.container}>
        <div className={styles.contentSubMenu}>
					<img
						src={iconMenu}
						alt=""
						className={styles.iconMenuImage}
						onClick={handleView}
					/>
					{viewMenu && (
						<div className={styles.contentMenuSmall}>
							<ul className={styles.ulMenu}>
							{pathname != "/" && <Link
									className={styles.itemMenu}
									name="/"
									onClick={handleItemView}
									to={'/'}
								>
									Start
								</Link>}
								
								<Link
									className={styles.itemMenu}
									name="/about"
									onClick={handleItemView}
									to={'/about'}
								>
									About
								</Link>
								<Link
									className={styles.itemMenu}
									name="/projects"
									onClick={handleItemView}
									to={'/projects'}
								>
									Projects
								</Link>
								<Link
									className={styles.itemMenu}
									name="/tech-kills"
									onClick={handleItemView}
									to={'/tech-kills'}
								>
									Skills
								</Link>
								<Link
									className={styles.itemMenu}
									name="/courses"
									onClick={handleItemView}
									to={'/courses'}
								>
									Courses
								</Link>
								<Link
									className={styles.itemMenu}
									name="/contact-me"
									onClick={handleItemView}
									to={'/contact-me'}
								>
									Contact
								</Link>
							</ul>
						</div>
					)}
				</div>
    </div>
  )
}

export default NavCompressed