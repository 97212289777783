import React from 'react';
import styles from '../assets/styles/components/views/About.module.css';
import cv from '../utils/CvMiguelFernandez.pdf';
import Footer from '../components/Footer';
const Home = () => {
	return (
		<div className={styles.container}>
			<main className={styles.contentMain}>
				<section className={styles.contentProfile}>
					<img
						src="https://res.cloudinary.com/dgp4xwknu/image/upload/v1729598479/Portfolio%20Miguel%20Fernandez/Edicion_1_o6l70z.jpg"
						alt="perfil"
					/>
				</section>
				<section className={styles.contentText}>
					<p>
					Hello, my name is Miguel and I am a Full Stack Developer with experience in Frontend and Backend development using technologies such as React, Redux, CSS Modules, Node.js, Express, Sequelize, and PostgreSQL. I have worked as a Tech Leader on projects, managing teams and improving efficiency. I quickly learn new technologies, adapting easily to changing environments. Through various projects, I have refined my skills in web architecture design, API integration, and performance optimization. I am constantly learning new tools and best practices to create efficient and scalable solutions. Open to new opportunities, eager to learn even more.
					</p>
					<div className={styles.contentButton}>
						<img
							src="https://icongr.am/entypo/download.svg?size=147&color=ffffff"
							alt=""
							className={styles.imageDownload}
						/>
						<button>
							<a href={cv} download="CV Miguel Fernandez.pdf">
								Download CV
							</a>
						</button>
					</div>
				</section>
			</main>
			<Footer />
		</div>
	);
};

export default Home;
