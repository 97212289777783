import React from 'react';

import Card from '../components/Card';
import styles from '../assets/styles/components/Cards.module.css';
const Cards = ({projects}) => {
	
	return (
		<div className={styles.container}>
			{projects.length !== 0 ? (
				projects.map(
					({
						id,
						name_project,
						description,
						picture,
						technology,
						deply,
						repositorio,
					}) => {
						return (
							<Card id={id} key={id} name={name_project} picture={picture} />
						);
					},
				)
			) : (
				<h1 className={styles.titleSecondary}>
					The projects have not yet been uploaded...
				</h1>
			)}
		</div>
	);
};

export default Cards;
