import React from 'react'
import styles from '../assets/styles/components/NavExpanded.module.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import iconMenu from '../assets/img/menu-2.png';
const NavExpanded = () => {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <ul className={styles.ul}>
                    {pathname != "/" && <Link className={styles.item} to={'/'}>
                        Start
                    </Link>}

                    <Link className={styles.item} to={'/about'}>
                        About
                    </Link>
                    <Link className={styles.item} to={'/projects'}>
                        Projects
                    </Link>
                    <Link className={styles.item} to={'/tech-kills'}>
                        Skills
                    </Link>
                    <Link className={styles.item} to={'/courses'}>
                        Courses
                    </Link>
                    <Link className={styles.item} to={'/contact-me'}>
                        Contact
                    </Link>
                </ul>
            </div>
        </div>
    )
}

export default NavExpanded