import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate,useLocation} from 'react-router-dom';
import iconMenu from '../assets/img/menu-2.png';
import NavCompressed from './NavCompressed';
import NavExpanded from './NavExpanded';

import styles from '../assets/styles/components/Nav.module.css';
const Nav = () => {

	const { pathname } = useLocation();

	const [viewMenuIcon, setViewMenuIcon] = useState(false);
	const [viewMenu, setViewMenu] = useState(false);
	
	const navigate = useNavigate();

	
	useEffect(() => {
		if (window.screen.width <= 576) {
			setViewMenuIcon(true);
		}
	}, []);

	return (
		<div className={styles.container}>
			{!viewMenuIcon ? (
				<NavExpanded/> 
			) : (
				<NavCompressed viewMenu={viewMenu} setViewMenu={setViewMenu} />
			)}
		</div>
	);
};

export default Nav;
