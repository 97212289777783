import React from 'react'
import styles from "../assets/styles/components/views/Courses.module.css"
import Footer from '../components/Footer';
import CardsCourses from '../components/CardsCourses';

const Courses = () => {
	
    const courses = [
		{
			id: 1,
			name_course: "Programming from scratch",
			description:
				"Programming from Scratch: A YouTube course designed for absolute beginners with no prior programming experience. Through 12 practical lessons (and counting), you'll learn essential concepts like data types, common errors, and basic control structures such as loops and conditional statements. The course stands out for its simplicity, using relatable comparisons to make understanding programming concepts easier and faster. Ideal for those looking to grasp the fundamentals of Python with hands-on practice.",
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1729601014/Portfolio%20Miguel%20Fernandez/Curso_de_Programacion_ihdg50.jpg',
			link:"https://www.youtube.com/playlist?list=PLl59slIUKc8V-inpn9GYT559vrCm8xN8d"
		},
		{
			id: 2,
			name_course: "Git",
			description:
				"Git from Scratch: A comprehensive YouTube course designed for beginners with no prior knowledge of version control. Through 23 practical lessons, you will learn how to manage your code efficiently using Git, from basic commands to branching and merging. The course follows a hands-on approach, with simple explanations and comparisons to help you understand the concepts quickly and easily. Perfect for those who want to start using Git for version control in their projects.",
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1729601146/Portfolio%20Miguel%20Fernandez/Intro_a3hbrc.png',
			link:"https://www.youtube.com/playlist?list=PLl59slIUKc8WljEv2ByY_g4-13akO6969"
		},
		{
			id: 3,
			name_course: "Markdown",
			description:
				"Markdown for Beginners: A 56-minute YouTube course divided into 8 sections, designed for those with no prior experience. This course covers everything from titles and text to code, lists, links, images, videos, badges, emojis, and tables. With clear, practical examples and a focus on simplicity, it’s the perfect introduction to Markdown, allowing you to start writing clean and structured documents with ease.",
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1729601181/Portfolio%20Miguel%20Fernandez/Portada_para_Video_hbvctw.jpg',
			link:"https://www.youtube.com/playlist?list=PLl59slIUKc8US9U0PMAqd3VHSkwX97wWh"
		},
		{
			id: 4,
			name_course: "Linux command lines",
			description:
				"Linux Command Line Basics: A YouTube course designed for beginners who want to learn how to navigate and use the Linux terminal. Through 12 practical lessons, you'll explore essential commands, helping you build confidence in managing files, directories, and processes from the command line. With a hands-on approach and simple explanations, this course is perfect for those looking to get started with Linux and terminal-based operations.",
			picture:
				'https://res.cloudinary.com/dgp4xwknu/image/upload/v1729601207/Portfolio%20Miguel%20Fernandez/Portada_del_Curso_pgnjcw.jpg',
			link:"https://www.youtube.com/playlist?list=PLl59slIUKc8VGQt-tYj6hn7TZx2nzOzF3"
		},
    ]
    return (
		<div className={styles.container}>
			<main className={styles.content}>
				<h1 className={styles.title}>My Courses</h1>
				<div className={styles.allProjects}>
					<CardsCourses courses={courses} />
				</div>
			</main>
			<Footer/>
		</div>
	);
}

export default Courses