import React from 'react';

import CardCourses from './CardCourses';
import styles from '../assets/styles/components/Cards.module.css';

const CardsCourses = ({courses}) => {
	
	return (
		<div className={styles.container}>
			{courses.length !== 0 ? (
				courses.map(
					({
						id,
						name_course,
						description,
						picture,
						link
					}) => {
						return (
							<CardCourses id={id} key={id} name_course={name_course} description={description} picture={picture} link={link} />
						);
					},
				)
			) : (
				<h1 className={styles.titleSecondary}>
					The couses have not yet been uploaded...
				</h1>
			)}
		</div>
	);
};

export default CardsCourses;
