import React from 'react';
import arrowLink from "../assets/img/arrorLink.svg"
import styles from '../assets/styles/components/CardCourses.module.css';
const CardsCourses = ({ id,name_course,description,picture,link }) => {
	
	
	return (
		<div className={styles.content}>
			<div className={styles.contentImage}>
				<img src={picture} alt="picture of project" title="Click on name" />
			</div>
			<h3 className={styles.name}>
				{name_course}
			</h3>
			<div className={styles.content_paragraph}>
				<p>{description}</p>
			</div>
			<div className={styles.content_arrow}>

			<a href={link} className={styles.link} target='_blank'>Go to Course </a>
			<img src={arrowLink} className={styles.arrowLink} alt="Click here" /> 
			</div>
		</div>
	);
};

export default CardsCourses;
